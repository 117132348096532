<template>
    <van-empty v-if="list == []"
      class="custom-image"
      image="../../assets/img/address.png"
      descrpition="暂无收货地址"
    />
  <van-address-list v-else
    class="myadd"
    v-model="chosenAddressId"
    :list="list"
 
  
    default-tag-text="默认"
    @add="onAdd"
    @edit="onEdit"
  />
</template>
<script>
import { getuserarea } from "@/api/components";

export default {
  data() {
    return {
      chosenAddressId: "1",
      list: [
        {
          id: "1",
          name: "张三",
          tel: "13000000000",
          address: "浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室",
          isDefault: true,
        },
        {
          id: "2",
          name: "李四",
          tel: "1310000000",
          address: "浙江省杭州市拱墅区莫干山路 50 号",
        },
      ],
      disabledList: [
        {
          id: "3",
          name: "王五",
          tel: "1320000000",
          address: "浙江省杭州市滨江区江南大道 15 号",
        },
      ],
    };
  },
  mounted() {
    this.getuserarealist();
  },
  methods: {
    // 获取地址
    async getuserarealist() {
      const data = await getuserarea({authorizations:'7a9bNjnn85ynqJtNaFSuVagJ/kWo4wIylBXpNSpwkqcbi2GwAsjbtGWGQpPDNppUkcEN7HsYYlQTaaOYhTI4jdXIghmAH0CEVek2'});
      
    },
    onAdd() {
      // Toast('新增地址');
      this.$router.push({ name: "editaddress" });
    },
    onEdit(item, index) {
      Toast("编辑地址:" + index);
      this.$router.push({ name: "editaddress", query: "" });
    },
  },
};
</script>
<style>
.myadd .van-address-item .van-radio__icon--checked .van-icon {
  background-color: rgba(29, 174, 252, 1) !important;
  border-color: rgba(29, 174, 252, 1) !important;
}
.myadd .van-tag--danger {
  background-color: rgba(29, 174, 252, 1) !important;
}
.van-address-list__add {
  background-color: rgba(29, 174, 252, 1) !important;
  border: 1px solid rgba(29, 174, 252, 1) !important;
}
</style>
